import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { setCookie, getCookie } from 'cookies-next';

import md5 from 'blueimp-md5';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import styles from './GlobalNotice.module.scss';

const GlobalNotice = ({
    priority = '',
    richText = '',
    links = [],
    showGlobalNotice = false,
}) => {
    const { t } = useTranslation();
    const [mounted, setMounted] = useState(false);
    const [isHidden, setHidden] = useState(false);

    const cookieValue = getCookie('globalnotice');

    useEffect(() => {
        setMounted(true);
    }, []);

    const noticeContent = JSON.stringify([priority, richText, links]);
    const hashContent = md5(noticeContent);

    if (
        (cookieValue === hashContent && mounted) ||
        (!showGlobalNotice && mounted) ||
        (isHidden && mounted)
    ) {
        return null;
    }

    const handleClick = () => {
        setHidden(true);
        setCookie('globalnotice', hashContent, { maxAge: 60 * 60 * 24 * 365 });
    };

    const isHighPriority = priority === 'high';

    return (
        <div
            className={classNames(styles['GlobalNotice'], {
                [styles['GlobalNotice--High']]: isHighPriority,
                [styles['GlobalNotice--Visible']]: mounted,
            })}>
            <div className={styles['GlobalNotice__Container']}>
                <div className={styles['GlobalNotice__Content']}>
                    <div
                        className={styles['GlobalNotice__RichText']}
                        dangerouslySetInnerHTML={{ __html: richText }}
                    />
                    {links.map((link, index) => (
                        <a
                            key={index}
                            className={styles['GlobalNotice__Link']}
                            href={link.href}
                            target={link.target}>
                            <span className={styles['GlobalNotice__LinkText']}>
                                {link.title}
                            </span>
                        </a>
                    ))}
                </div>
                <button
                    className={styles['GlobalNotice__Button']}
                    onClick={handleClick}>
                    <Icon
                        type={'close'}
                        modifiers={['white']}
                        dimensions={{ width: '10px', height: '10px' }}
                    />
                    <span className="sr-only">{t('GlobalNotice.close')}</span>
                </button>
            </div>
        </div>
    );
};

GlobalNotice.propTypes = {
    richText: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
            target: PropTypes.string,
        })
    ),
    showGlobalNotice: PropTypes.bool,
};

export default GlobalNotice;
