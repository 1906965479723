import React from 'react';
import { basePageWrap } from '../BasePage';
import PropTypes from 'prop-types';

import GlobalNotice from '../../components/GlobalNotice/GlobalNotice';
import MyPagesHeader from '../../components/MyPagesHeader/MyPagesHeader';
import MyPagesFooter from '../../components/MyPagesFooter/MyPagesFooter';
import StreamField from '../../components/StreamField/StreamField';

import styles from './MyPagesSiteStartPage.module.scss';

const MyPagesSiteStartPage = ({ siteSetting, content }) => {
    return (
        <div className={styles['MyPagesSiteStartPage']}>
            {siteSetting && siteSetting.header && (
                <MyPagesHeader
                    {...siteSetting.header}
                    shouldHideLogout={true}
                    shouldShowNavigation={false}
                />
            )}
            <main className={styles['MyPagesSiteStartPage__Main']}>
                {siteSetting && siteSetting.globalNotice && (
                    <GlobalNotice {...siteSetting.globalNotice} />
                )}
                {content && <StreamField items={content} />}
            </main>
            {siteSetting && siteSetting.footer && (
                <MyPagesFooter {...siteSetting.footer} />
            )}
        </div>
    );
};

MyPagesSiteStartPage.propTypes = {
    siteSetting: PropTypes.object,
    hero: PropTypes.object,
    content: PropTypes.array,
};

export default basePageWrap(MyPagesSiteStartPage);
